//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import api from "./networking/api";
import common from "./common/common";
import "./common/previewImage";
import { LocalStorage } from "quasar";
import calendar from "./calendar/calendar";
import MediaRecord from '@/components/MediaRecord.vue';
export default {
  components: {
    calendar,
    MediaRecord
  },
  name: "FigureSignIndex",
  data() {
    return {
      userInfo: {},
      date: this.$moment().format("YYYY-MM-DD"),
      dialogFormVisible: true,
      storeListLoading: false,
      storesOptions: [],
      stores: [],
      curStore: "",
      storeName: "",
      storeId: "",
      imageHost:api.imageHost,
      signList:[],
      signInfo:{
        isToday:true,
        isNeedSign:false
      },
      latitude:"",
      longitude:"",
      showRecord:false
    };
  },
  computed: {
    noSignDays() {
      let num = 0;
      if(this.$moment().startOf('month').isSame(this.$moment(this.date,'yyyy-MM-DD').startOf('month'), 'day')){
        let signedDays = this.signList.length
        //未打卡当月计算不包含当天
        this.signList.map(v=>{
          if(this.$moment().format('yyyy-MM-DD') == this.$moment(v.signTime).format('yyyy-MM-DD')){
            signedDays = signedDays - 1
          }
        })
        num = this.$moment().diff(this.$moment(this.date).startOf('month'), 'days') - signedDays
      }else{
        num = this.$moment(this.date).endOf('month').diff(this.$moment(this.date).startOf('month'), 'days') + 1 - this.signList.length
      }
      return num
    },
  },
  methods: {
    getSign(){ 
      var that = this;
      if(this.isMobile()){
        wx.getLocation({
          type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success: function (res) {
            console.log('getLocation',res);
            that.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
            that.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
            if(window.MediaRecorder && typeof window.MediaRecorder.isTypeSupported == "function" && navigator.mediaDevices.getUserMedia){
              navigator.mediaDevices.getUserMedia({
                audio:true
              }).then((stream)=>{
                console.log('success',stream);
                stream.getTracks().forEach(function (track) {
                  track.stop()
                })
                that.showRecord = true
              }).catch(function (err) {
                console.log('err',err);
                wx.chooseImage({
                  count: 1, // 默认9
                  sizeType: ["original"], // '' 'compressed' 可以指定是原图还是压缩图，默认二者都有
                  sourceType: ["camera"], // 可以指定来源是相册还是相机，默认二者都有
                  defaultCameraMode: "front", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。（注：用户进入拍照界面仍然可自由切换两种模式）
                  success: function (res) {
                    var localIds = res.localIds; // 返回选定照片的本地ID列表，
                    var localId = localIds[0];
                    that.uploadImage(localId);
                  },
                  fail(err){
                    console.log('fail',err);
                  },
                  complete(res){
                    console.log('complete',res);
                  }
                });
              })
            }else{
              wx.chooseImage({
                count: 1, // 默认9
                sizeType: ["original"], // '' 'compressed' 可以指定是原图还是压缩图，默认二者都有
                sourceType: ["camera"], // 可以指定来源是相册还是相机，默认二者都有
                defaultCameraMode: "front", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。（注：用户进入拍照界面仍然可自由切换两种模式）
                success: function (res) {
                  var localIds = res.localIds; // 返回选定照片的本地ID列表，
                  var localId = localIds[0];
                  that.uploadImage(localId);
                },
                fail(err){
                  console.log('fail',err);
                },
                complete(res){
                  console.log('complete',res);
                }
              });
            }
          },
          //安卓企微拒绝位置授权，应用内授权允许位置后无异常提示
          complete(res){
            console.log(res);
            if(res.errMsg != 'getLocation:ok' || !res.latitude){
              that.$toast({message: '获取定位失败，请打开定位权限!', duration: 3000})
            }
          },
          fail(err){
            console.log(err);
            that.$toast({message: '获取定位失败，请打开定位权限!', duration: 3000})
          }
      });
        
      }
      else{
        this.$toast({message: '请至移动端应用内打卡!', duration: 3000})
      }
    },
    cancelRecord(e){
      this.showRecord = false
    },
    confirmRecord(e){
      this.showRecord = false
      console.log('confirmRecord',123,typeof e);
      this.uploadFileToOss(e,"image");
    },
    
    uploadFileToOss(file, type = "image") {
      console.log(file,'file');
      let _this = this;
      let params = {
        code:'figuresign/' + this.curStore.brand,
        type:'store'
      }
      this.axios.get("/consumer/oss/doGet",{
        params
    }).then(function (res) {
        console.log(res);
        let name = file.name;
        let formData = new FormData();
        formData.append("name", name);
        formData.append("key", res.data.data.dir + name);
        formData.append("policy", res.data.data.policy);
        formData.append("OSSAccessKeyId", res.data.data.accessid);
        formData.append("success_action_status", "200");
        formData.append("callback", res.data.data.callback);
        formData.append("signature", res.data.data.signature);
        formData.append("file", file);
        formData.forEach((key, val) => {
          console.log("key %s: value %s", key, val)
        })
        _this.axios
          .post(
            "https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/",
            formData
          )
          .then((response) => {
            _this.doSign(
              "https://bxn-qy-workspace.oss-cn-hangzhou.aliyuncs.com/" +
                res.data.data.dir +
                name
            );
            console.log(response);
          }).catch((err)=>{
            console.log(err);
          });
      });
    },
    doSign: function (imgUrl) {
      let that = this;
      let postData = {
          signImageUrl:imgUrl,
          storeId:this.storeId,
          signTime:this.date,
          signContent:"",
          latitude:that.latitude,
          longitude:that.longitude,
      };
      this.axios
        .post(api.addStoreFigureSignOss, postData)
        .then(function (response) {
          that.$toast.clear();
          if (common.success(response)) {
            that.$toast({message: '打卡成功!', duration: 3000})
            that.getData()
          } else {
            that.$dialog.alert({
              message: response.data.msg || response.data.reason,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    showConsole(){
      if(this.date == '2023-07-31'){
        var script = document.createElement('script');
        script.src = 'https://cdn.bootcdn.net/ajax/libs/vConsole/3.9.0/vconsole.min.js'
        document.getElementsByTagName('body')[0].appendChild(script)
        script.onload = ()=>{
          var vConsole = new VConsole();
        }
      }
    },
    async changeChosenAttendanceDetail(detail) {
      console.log(detail, "changeChosenAttendanceDetail");
      if(this.$moment(this.date).isSame(this.$moment(detail.date,'yyyy-MM-DD'), 'month')){
        console.log(1);
        this.date = detail.date;
        this.getStoreFigureSignDetailList()
      }else{
        console.log(2);
        this.date = detail.date;
        this.getData()
      }
     
    },
    requestUserInfo: function () {
      let that = this;
      this.storeListLoading = true;
      this.axios
        .get(api.getUserInfo)
        .then(function (response) {
          that.storeListLoading = false;
          if (common.success(response)) {
            let data = response.data.data;
            that.userInfo = data;
            if (!data.ownerShops) {
              alert("门店权限未配置");
              return;
            }

            let saleStore = data.attr && data.attr.saleStore || [];

            that.stores = [];
            for (let i = 0; i < saleStore.length; i++) {
              that.stores.push({
                shopName: saleStore[i].title,
                name: saleStore[i].storeId + "_",
                storeId: saleStore[i].storeId,
                brand: saleStore[i].brand,
              });
            }
            // if (that.stores.length == 1) {
            that.curStore = that.stores[0];
            that.handleSelectStore();
            // }
          } else {
            alert("获取getUserInfo失败：" + response.data.reason);
          }
        })
        .catch(function (error) {
          alert("获取getUserInfo失败：" + error);
        })
        .then(function () {
          that.storeListLoading = false;
        });
    },
    filterFn(val, update, abort) {
      update(() => {
        const needle = val.toUpperCase();
        this.storesOptions = this.stores.filter(function (v) {
          let result = v.name.toUpperCase().indexOf(needle) > -1;
          let result1 = v.shopName.indexOf(needle) > -1;
          if (result || result1) {
            return true;
          }
          return false;
        });
      });
    },

    handleSelectStore: function () {
      if (!this.curStore || this.curStore.length == 0) {
        this.$dialog.alert({
          message: "请先选择门店",
        });
        return;
      }
      this.shopName = this.curStore.shopName;
      this.storeId = this.curStore.storeId;
      this.dialogFormVisible = false;
      this.signList = []
      this.getData();
    },
    getData() {
      let postData = {
        queryMapObject: {
          storeId:this.storeId,
          startTime:this.$moment(this.date,'yyyy-MM-DD').startOf('month').format("yyyy-MM-DD") + ' 00:00:00',
          endTime:this.$moment(this.date,'yyyy-MM-DD').endOf('month').format("yyyy-MM-DD") + ' 23:59:59',
        }
      };
      this.axios
        .post(api.getStoreFigureSignList, postData)
        .then((res) => {
          if (res.data && res.data.code == 0) {
            this.signList = res.data.data || []
          }else{
            this.signList = []
          }
          this.getStoreFigureSignDetailList()
        });
    },
    getStoreFigureSignDetailList() {
      let postData = {
        queryMapObject: {
          storeId:this.storeId,
          startTime:this.date + ' 00:00:00',
          endTime:this.date + ' 23:59:59',
        }
      };
      this.axios
        .post(api.getStoreFigureSignDetailList, postData)
        .then((res) => {
          if (res.data && res.data.code == 0) {
            let item = res.data.data && res.data.data[0] || {}
            item.isToday = this.$moment().isSame(this.$moment(this.date,'yyyy-MM-DD'), 'day')
            item.isNeedSign = this.$moment(this.date,'yyyy-MM-DD').isSameOrBefore(this.$moment(), 'day')
            this.signInfo = item
          }else{
            this.signInfo = {
              isToday:this.$moment().isSame(this.$moment(this.date,'yyyy-MM-DD'), 'day'),
              isNeedSign:this.$moment(this.date,'yyyy-MM-DD').isSameOrBefore(this.$moment(), 'day')
            }
          }
          this.$forceUpdate()
        });
    },
    requestJsSdkConfig: function () {
      let that = this;
      let agentId = LocalStorage.getItem("agentId");
      let corpId = LocalStorage.getItem("corpId");
      that.requestUserInfo();
      this.axios
        .get(api.getJsSdkConfig, {
          params: {
            agentId,
            corpId,
            url: encodeURIComponent(window.location.href.split("#")),
          },
        })
        .then(function (response) {
          if (common.success(response)) {
            let data = response.data.data;
            that.wxConfigData = {
              nonceStr: data.nonceStr,
              timestamp: data.timestamp,
              signature: data.signature,
              appId: data.appId,
            };
            that.wxConfig(that.wxConfigData);
          } else {
            alert("获取JsSdkConfig失败：" + response.data.reason);
          }
        })
        .catch(function (error) {
          alert("获取JsSdkConfig失败：" + error);
        })
        .then(function () {});
    },
    chooseImage: function () {
      var that = this;
      if(this.isMobile()){
        wx.getLocation({
          type: 'wgs84', // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
          success: function (res) {
            console.log('getLocation',res);
            that.latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
            that.longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
              // var speed = res.speed; // 速度，以米/每秒计
              // var accuracy = res.accuracy; // 位置精度
              wx.chooseImage({
                count: 1, // 默认9
                sizeType: ["original"], // '' 'compressed' 可以指定是原图还是压缩图，默认二者都有
                sourceType: ["camera"], // 可以指定来源是相册还是相机，默认二者都有
                defaultCameraMode: "front", //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。（注：用户进入拍照界面仍然可自由切换两种模式）
                success: function (res) {
                  var localIds = res.localIds; // 返回选定照片的本地ID列表，
                  var localId = localIds[0];
                  that.uploadImage(localId);
                },
                fail(err){
                  console.log('fail',err);
                },
                complete(res){
                  console.log('complete',res);
                }
              });
          },
          //安卓企微拒绝位置授权，应用内授权允许位置后无异常提示
          complete(res){
            console.log(res);
            if(res.errMsg != 'getLocation:ok' || !res.latitude){
              that.$toast({message: '获取定位失败，请打开定位权限!', duration: 3000})
            }
          },
          fail(err){
            console.log(err);
            that.$toast({message: '获取定位失败，请打开定位权限!', duration: 3000})
          }
      });
        
      }else{
        this.$toast({message: '请至移动端应用内打卡!', duration: 3000})
      }

    },
   
    uploadImage: function (localId) {
      var that = this;
      wx.uploadImage({
        localId: localId, // 需要上传的图片的本地ID，由chooseImage接口获得
        isShowProgressTips: 1, // 默认为1，显示进度提示
        success: function (res) {
          var serverId = res.serverId; // 返回图片的服务器端ID
          console.log("serverId:" + serverId);
          that.uploadImageToServer(serverId);
        },
      });
    },
    uploadImageToServer: function (serverId) {
      let that = this;
      let postData = {
          mediaId:serverId,
          storeId:this.storeId,
          signTime:this.date,
          signContent:"",
          latitude:that.latitude,
          longitude:that.longitude,
      };
      this.axios
        .post(api.uploadImage, postData)
        .then(function (response) {
          that.$toast.clear();
          if (common.success(response)) {
            that.$toast({message: '打卡成功!', duration: 3000})
            that.getData()
          } else {
            that.$dialog.alert({
              message: response.data.msg || response.data.reason,
            });
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    previewImage: function () {
      let url = this.signInfo.signImageUrl
      if (
       this.isMobile()
      ) {
        wx.previewImage({
          current: url, // 当前显示图片的http链接
          urls: [url], // 需要预览的图片http链接列表
        });
      } else {
        window.previewImage.start({
          current: url, // 当前显示图片的http链接
          urls: [url], // 需要预览的图片http链接列表
        });
        
      }
    },
    isMobile(){
     return /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent)
    },
    wxConfig: function (data) {
      wx.config({
        beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: data.appId, // 必填，企业微信的corpID
        timestamp: data.timestamp, //  必填，生成签名的时间戳
        nonceStr: data.nonceStr, //  必填，生成签名的随机串
        signature: data.signature, // 必填，签名，见 附录-JS-SDK使用权限签名算法
        jsApiList: [
          "chooseVideo",
          "chooseMedia",
          "chooseImage",
          "uploadImage",
          "previewImage",
          "getLocalImgData",
          "downloadImage",
          "getLocation"
        ], // 必填，需要使用的JS接口列表，凡是要调用的接口都需要传进来
      });

      wx.ready(function () {
        // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中。
      });

      wx.error(function (res) {
        alert(JSON.stringify(res));
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      });
    },
  },

  mounted: function () {
    
    window.document.title = "形象打卡";
    // this.requestUserInfo();
    this.requestJsSdkConfig();
  },
};
