//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

function log(message) {
  console.log(message);
}
var mediaRecorder = null
var timer = null;
export default {
  name:"VideoRecord",
  props:{
    show:{
      type:Boolean,
      default:true
    },
    type:{
      type:String,
      default:'image'//video image
    }
  },
  data() {
    return {
      targetSrc:'',
      isRecording:false,
      localStream:null,
      time:0,
      delay:10,
      showTimer:false,
      facingMode:'user'
    };
  },
  mounted(){
    this.initRecord()
  },
  methods: {
    changeFaceMode(){
      let _this = this
      console.log('changeFaceMode',this.localStream,this.facingMode,123);
      if(this.localStream && this.localStream.active == true){
        this.localStream.getTracks().forEach(function (track) {
          track.stop()
        })
      }
      this.facingMode = this.facingMode == 'user' ? 'environment' : 'user'
      setTimeout(()=>{
        _this.initRecord()
      },100)
    },
    toggleShowTimer(){
      this.showTimer = !this.showTimer;
    },
    cancelRecord(){
      this.localStream.getTracks().forEach(function (track) {
        track.stop()
      })
      this.$emit('cancelRecord')
    },
    random_string(len) {
      len = len || 32;
      var chars = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678";
      var maxPos = chars.length;
      var pwd = "";
      for (var i = 0; i < len; i++) {
        pwd += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      return pwd;
    },
    confirmRecord(){
      this.localStream.getTracks().forEach(function (track) {
        track.stop()
      })
      this.$emit('confirmRecord',this.type == 'image' ? this.base64ToFile(this.targetSrc, this.random_string(5) + '.jpg') : new File([this.targetSrc], this.random_string(5) + '.mp4'))
    },
    // Base64 转 File
    base64ToFile(data) {
      // 将base64 的图片转换成file对象上传 atob将ascii码解析成binary数据
      let binary = atob(data.split(',')[1]);
      let mime = data.split(',')[0].match(/:(.*?);/)[1];
      let array = [];
      for (let i = 0; i < binary.length; i++) {
          array.push(binary.charCodeAt(i));
      }
      let fileData = new Blob([new Uint8Array(array)], {
          type: mime,
      });
      let file = new File([fileData], `${new Date().getTime()}.png`, {
          type: mime
      });
      return file;
    },
    stopPop(e){
      e.stopPropagation()
      e.preventDefault()
    },
    startRecord(delay = 0){
      let _this = this;
      _this.showTimer = false;
      _this.time = 0;
      _this.targetSrc = ''
      _this.isRecording = false;
      let localStream = this.localStream
      var playbackVideoElement = document.querySelector("#playback");
      var liveVideoElement= document.querySelector("#live"); 
      playbackVideoElement.controls = false;
      liveVideoElement.style.setProperty('aspect-ratio', '9 / 16');
      playbackVideoElement.style.setProperty('aspect-ratio', '9 / 16');
        if (localStream == null) {
          alert("Could not get local stream from mic/camera");
        } else {
          var chunks = [];
          /* use the stream */
          log("Start recording...");
          if (typeof MediaRecorder.isTypeSupported == "function") {
            /*
				MediaRecorder.isTypeSupported is a function announced in https://developers.google.com/web/updates/2016/01/mediarecorder and later introduced in the MediaRecorder API spec http://www.w3.org/TR/mediastream-recording/
			*/
            if (MediaRecorder.isTypeSupported("video/webm;codecs=h264")) {
              var options = { mimeType: "video/webm;codecs=h264" };
            } else if (MediaRecorder.isTypeSupported("video/webm")) {
              var options = { mimeType: "video/webm" };
            } else if (MediaRecorder.isTypeSupported("video/mp4")) {
              //Safari 14.0.2 has an EXPERIMENTAL version of MediaRecorder enabled by default
              var options = {
                mimeType: "video/mp4",
                videoBitsPerSecond: 2500000,
              };
            }

            log("Using " + JSON.stringify(options));
            mediaRecorder = new MediaRecorder(localStream, options);

          } else {
            log(
              "isTypeSupported is not supported, using default codecs for browser"
            );
            mediaRecorder = new MediaRecorder(localStream);
          }

          mediaRecorder.ondataavailable = function (e) {
            log('mediaRecorder.ondataavailable, e.data.size='+e.data.size);
            if (e.data && e.data.size > 0) {
              chunks.push(e.data);
            }
          };

          mediaRecorder.onerror = function (e) {
            log("mediaRecorder.onerror: " + e);
          };

          mediaRecorder.onstart = function () {
            _this.isRecording = true
            log(
              "mediaRecorder.onstart, mediaRecorder.state = " +
                mediaRecorder.state
            );
            console.log(_this.type,delay,_this.delay);
            if(_this.type == 'image'){
              if(delay == -1){
                _this.isRecording = false
                timer && clearInterval(timer)
                return
              }else if(delay == 0){
                _this.stopRecord()
                return
              }else{
                _this.delay = delay
              }
              _this.time = parseInt(_this.delay)
              timer = setInterval(() => {
                _this.time--
                if(_this.time <= 0){
                  _this.stopRecord()
                }
              }, 1000);
            }else{
              timer = setInterval(() => {
                _this.time++
                if(_this.time >= 60){
                  _this.$toast({message: '录像最长时间60s', duration: 2000});
                  _this.stopRecord()
                }
              }, 1000);
            }
            
            localStream.getTracks().forEach(function (track) {
              if (track.kind == "audio") {
                log(
                  "onstart - Audio track.readyState=" +
                    track.readyState +
                    ", track.muted=" +
                    track.muted
                );
              }
              if (track.kind == "video") {
                log(
                  "onstart - Video track.readyState=" +
                    track.readyState +
                    ", track.muted=" +
                    track.muted
                );
              }
            });
          };

          mediaRecorder.onstop = function (e) {
            log(
              "mediaRecorder.onstop, mediaRecorder.state = " +
                mediaRecorder.state
            );
            clearInterval(timer)
            _this.time = 0
            // playbackVideoElement.controls = true;
            if(_this.type == 'image'){
              let canvas= document.createElement('canvas');
              canvas.width = liveVideoElement.videoWidth;
              canvas.height = liveVideoElement.videoHeight;
              let context = canvas.getContext('2d');
              context.drawImage(liveVideoElement, 0, 0);
              _this.targetSrc = canvas.toDataURL('image/jpeg')
            }else{
              var recording = new Blob(chunks, { type: mediaRecorder.mimeType });
              playbackVideoElement.srcObject = recording;
              _this.targetSrc = recording;
              playbackVideoElement.autoplay = true;
              playbackVideoElement.controls = false;
              playbackVideoElement.play();
            }
          };
          mediaRecorder.onwarning = function (e) {
            log("mediaRecorder.onwarning: " + e);
          };
          mediaRecorder.start(200);
        }
    },
    stopRecord(){
      this.isRecording = false
      mediaRecorder.stop();
    },
    //访问用户媒体设备的兼容方法
   getUserMedia(constrains){
      if(navigator.mediaDevices.getUserMedia){
          //最新标准API
        return  navigator.mediaDevices.getUserMedia(constrains);
      } else if (navigator.webkitGetUserMedia){
          //webkit内核浏览器
        return  navigator.webkitGetUserMedia(constrains);
      } else if (navigator.mozGetUserMedia){
          //Firefox浏览器
        return  navagator.mozGetUserMedia(constrains);
      } else if (navigator.getUserMedia){
          //旧版API
        return  navigator.getUserMedia(constrains);
      }
    },
    initRecord() {
      let _this = this;
      mediaRecorder && mediaRecorder.stop();
      mediaRecorder = null;
      this.localStream = null
      "use strict";
      if(_this.type == 'image'){
        var constraints = {
        audio: false,
        video: {
          height: { min: 1280,ideal:1280 },
          width: { min: 720,ideal:720 },
          // aspectRatio: 1.7777777778,
          aspectRatio: { exact: 1.78 },
          facingMode: _this.facingMode,
          // framerate: 60,
        },
      };
      }else{
        var constraints = {
        audio: { noiseSuppression: false },
        video: {
          height: { min: 640, ideal: 1280, max: 1280 },
          width: { min: 480, ideal: 720, max: 720 },
          // advanced: [
          //   {aspectRatio: 1.778}
          // ],
          // aspectRatio: 16/9,
          // aspectRatio: { exact: 1.7777777778 },
          // framerate: 60,
          facingMode: _this.facingMode,
        },
      };
      }

      
      var liveVideoElement = document.querySelector("#live");

      // liveVideoElement.controls = false;

      var localStream = null;
      var soundMeter = null;
       //defaults to webm but we switch to mp4 on Safari 14.0.2+
      if (window.MediaRecorder == undefined) {
        alert(
          "MediaRecorder not supported on your browser, use the latest version of Firefox or Chrome"
        );
      } else {
        navigator.mediaDevices.enumerateDevices()
        .then(function (devices) {
          devices.forEach(function (device) {
            if(_this.facingMode == 'user' && device.kind == 'videoinput' && device.label.indexOf('前') > -1){
              constraints.video.deviceId = device.deviceId
            }
          });
        })
        .catch(function (err) {
          console.log(err.name + ": " + err.message);
        });
        _this.getUserMedia(constraints)
          .then(function (stream) {
            localStream = stream;
            localStream.getTracks().forEach(function (track) {
              track.applyConstraints(constraints)
              if (track.kind == "audio") {
                track.onended = function (event) {
                  log(
                    "audio track.onended Audio track.readyState=" +
                      track.readyState +
                      ", track.muted=" +
                      track.muted
                  );
                };
              }
              if (track.kind == "video") {
                track.onended = function (event) {
                  log(
                    "video track.onended Audio track.readyState=" +
                      track.readyState +
                      ", track.muted=" +
                      track.muted
                  );
                };
              }
            });
            _this.localStream = localStream
            liveVideoElement.srcObject = localStream;
            liveVideoElement.play();
            try {
              window.AudioContext =
                window.AudioContext || window.webkitAudioContext;
              window.audioContext = new AudioContext();
            } catch (e) {
              log("Web Audio API not supported.");
            }
            soundMeter = window.soundMeter = new SoundMeter(
              window.audioContext
            );
            soundMeter.connectToSource(localStream, function (e) {
              if (e) {
                log(e);
                return;
              } else {
                /*setInterval(function() {
            log(Math.round(soundMeter.instant.toFixed(2) * 100));
          }, 100);*/
              }
            });
          })
          .catch(function (err) {
            /* handle the error */
            log("navigator.getUserMedia error: " + err);
            _this.$toast({message: '未找到摄像头，无法拍照!', duration: 3000})
            _this.cancelRecord()
          });
      }



      function SoundMeter(context) {
        this.context = context;
        this.instant = 0.0;
        this.slow = 0.0;
        this.clip = 0.0;
        this.script = context.createScriptProcessor(2048, 1, 1);
        var that = this;
        this.script.onaudioprocess = function (event) {
          var input = event.inputBuffer.getChannelData(0);
          var i;
          var sum = 0.0;
          var clipcount = 0;
          for (i = 0; i < input.length; ++i) {
            sum += input[i] * input[i];
            if (Math.abs(input[i]) > 0.99) {
              clipcount += 1;
            }
          }
          that.instant = Math.sqrt(sum / input.length);
          that.slow = 0.95 * that.slow + 0.05 * that.instant;
          that.clip = clipcount / input.length;
        };
      }

      SoundMeter.prototype.connectToSource = function (stream, callback) {
        console.log("SoundMeter connecting");
        try {
          this.mic = this.context.createMediaStreamSource(stream);
          this.mic.connect(this.script);
          // necessary to make sample run, but should not be.
          this.script.connect(this.context.destination);
          if (typeof callback !== "undefined") {
            callback(null);
          }
        } catch (e) {
          console.error(e);
          if (typeof callback !== "undefined") {
            callback(e);
          }
        }
      };
      SoundMeter.prototype.stop = function () {
        this.mic.disconnect();
        this.script.disconnect();
      };
    },
  },
};
